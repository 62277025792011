.mapboxgl-popup-content {
    padding: 0 !important;
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.2));
}

/* GIS Layers Toggle Control Switch colorScheme override */
.chakra-switch__track[data-checked] {
    --switch-bg: black !important;
}

.widgetWrapper > .chakra-collapse {
    flex: 1 1;
    overflow-y: auto !important;
}

.project-edit-instructions:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-right-color: white;
    border-left: 0;
    margin-top: -15px;
    margin-left: -15px;
}

/* Allow for dynamic height and panel scrolling in map sidebar */
.sidebar .chakra-collapse {
    display: flex !important;
}

#autocompleteMenu > div {
    min-width: auto !important;
    max-width: 300px;
    position: absolute !important;
    inset: 39px 0px 0px 0px !important;
}
